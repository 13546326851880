import { createColumnHelper } from '@tanstack/react-table'
import { useRouter } from 'next/router'
import { OpenStackServerStatus, useDashboardVirtualMachinesQuery } from 'sdk'
import {
  EyeIcon,
  Hint,
  Link,
  Pagination,
  queryResultToTableState,
  Table,
} from 'ui'
import SSHConnectionActions from '../shared/SSHConnectionActions'
import { StatusIcon } from '../vm/ServerTable'

const RUNNING_STATUSES = [
  OpenStackServerStatus.Active,
  OpenStackServerStatus.Build,
  OpenStackServerStatus.Initial,
  OpenStackServerStatus.RequestCreate,
  OpenStackServerStatus.RequestDelete,
  OpenStackServerStatus.Creating,
  OpenStackServerStatus.RequestStart,
  OpenStackServerStatus.RequestShutoff,
  OpenStackServerStatus.RequestHardReboot,
  OpenStackServerStatus.RequestSoftReboot,
  OpenStackServerStatus.Shutoff,
  OpenStackServerStatus.Reboot,
  OpenStackServerStatus.HardReboot,
  OpenStackServerStatus.Paused,
  OpenStackServerStatus.Suspended,
]

interface ServerTableProps {
  createdByUsername?: string
}

export default function ServerTable(props: ServerTableProps): JSX.Element {
  const pageSize = 10
  const queryParamPrefix = 'vm_'
  const router = useRouter()

  const query = useDashboardVirtualMachinesQuery(
    {
      first: pageSize,
      offset: Number(router.query.vm_offset) || 0,
      orderBy: '-createdAt',
      createdByUsername: props.createdByUsername,
      status: RUNNING_STATUSES,
    },
    {
      keepPreviousData: true,
      refetchInterval: 10 * 1000,
    }
  )
  const { data, isLoading, isSuccess } = query
  if (isLoading) {
    return <p>Loading...</p>
  }
  if (!isSuccess) {
    return <p>Loading failed, please reload to try again</p>
  }

  const servers = data.servers.edges.map((edge) => edge.node)
  const columnHelper = createColumnHelper<(typeof servers)[number]>()

  const columns = [
    columnHelper.accessor('teamName', {
      id: 'teamName',
      header: () => 'Team',
      cell: ({ row: { original: server } }) => (
        <>
          <EyeIcon />
          <Link
            href={`/teams/${server.teamSlug}/instances?status=RUNNING&name=${server.name}`}
          >
            {server.teamName}
          </Link>
        </>
      ),
    }),
    columnHelper.accessor('name', {
      id: 'name',
      header: () => 'Name',
      cell: (info) => {
        const server = info.row.original
        return (
          <>
            {server.name}
            <Hint
              message={`VM details:
Flavor: ${server.openstackFlavor.name}
Image: ${server.openstackImage.name}`}
            />
          </>
        )
      },
    }),
    columnHelper.display({
      id: 'nonfilterable_Connect',
      header: () => 'Connect',
      cell: (info) => {
        const server = info.row.original
        const host = server.openstackFloatingIp
        if (server.status === OpenStackServerStatus.Active && host) {
          return (
            <SSHConnectionActions
              user={server.openstackImage.defaultSshUser}
              host={host}
            />
          )
        } else {
          return (
            <>
              <StatusIcon openStackServerStatus={server.status} />
              {server.status}
            </>
          )
        }
        return null
      },
    }),
  ]

  const totalCount = data.servers.totalCount || 0

  return (
    <>
      <p>
        {isLoading
          ? 'Loading instances...'
          : `${totalCount} instance${totalCount === 1 ? '' : 's'} found`}
      </p>
      <Table data={queryResultToTableState(query, servers)} columns={columns} />
      <Pagination
        totalCount={totalCount}
        pageSize={pageSize}
        queryParamPrefix={queryParamPrefix}
      />
    </>
  )
}
