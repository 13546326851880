import { Select, useModal, ConfirmForm, triggerLink } from 'ui'
import {
  OpenStackServerType,
  useServerDeleteMutation,
  useServerStartMutation,
  useServerShutoffMutation,
  useServerSoftRebootMutation,
  useServerHardRebootMutation,
  OpenStackServerStatus,
} from 'sdk'
import { useQueryClient } from '@tanstack/react-query'
import { useRouter } from 'next/router'
import { useUrls } from 'utils'

function isOptionDisabled(
  server: OpenStackServerType,
  action: string
): boolean {
  if (action === 'Delete' || action === 'Admin Detail Page') return false

  const statusAllowed: Record<string, string[]> = {
    Start: [OpenStackServerStatus.Shutoff],
    Shutoff: [OpenStackServerStatus.Active, OpenStackServerStatus.Error],
    'Soft Reboot': [OpenStackServerStatus.Active],
    'Hard Reboot': [
      OpenStackServerStatus.Active,
      OpenStackServerStatus.Error,
      OpenStackServerStatus.HardReboot,
      OpenStackServerStatus.Paused,
      OpenStackServerStatus.Reboot,
      OpenStackServerStatus.Shutoff,
      OpenStackServerStatus.Suspended,
    ],
  }
  if (statusAllowed[action].includes(server.status)) return false
  return true
}
interface ServerActionsProps {
  server: OpenStackServerType
  isLoading: boolean
  disabled: boolean
  isSuperuser: boolean
}

export default function ServerActions({
  server,
  isLoading,
  disabled,
  isSuperuser,
}: ServerActionsProps): JSX.Element {
  const queryClient = useQueryClient()
  const router = useRouter()
  const { ADMIN_URL } = useUrls()

  const { mutateAsync: mutateDelete } = useServerDeleteMutation()
  const { mutateAsync: mutateStart } = useServerStartMutation()
  const { mutateAsync: mutateShutoff } = useServerShutoffMutation()
  const { mutateAsync: mutateSoftReboot } = useServerSoftRebootMutation()
  const { mutateAsync: mutateHardReboot } = useServerHardRebootMutation()

  const actions = ['Delete', 'Start', 'Shutoff', 'Soft Reboot', 'Hard Reboot']
  if (isSuperuser) {
    actions.push('Admin Detail Page')
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const mutations: Record<string, any> = {
    Delete: { mutate: mutateDelete },
    Start: { mutate: mutateStart },
    Shutoff: { mutate: mutateShutoff },
    'Soft Reboot': { mutate: mutateSoftReboot },
    'Hard Reboot': {
      mutate: mutateHardReboot,
      disclaimer:
        "This is a non-graceful reboot, equal to loss of power from the VM's point-of-view. This may cause for example file system corruption. Only use as the last resort.",
    },
  }
  const { openModal, closeModal } = useModal()

  const runSelectedAction = async (action: string) => {
    try {
      await mutations[action].mutate({ input: { id: server.id } })
    } catch (error) {
      return `Failed to ${action} ${server.name}: ${error}`
    } finally {
      await queryClient.invalidateQueries(['servers'])
    }
    return null
  }

  function confirmSelectedAction(action: string) {
    const Component = (
      <ConfirmForm
        heading={`${action} Instance`}
        prompt={`Are you sure you want to ${action} the following instance?`}
        promptItems={[
          `Server: ${server.name}`,
          `Created By: ${server.createdBy.firstName} ${server.createdBy.lastName}`,
        ]}
        warningDisclaimer={mutations[action].disclaimer}
        onSubmit={() => runSelectedAction(action)}
        onCancel={closeModal}
        onSubmitComplete={() => {
          closeModal()
        }}
      />
    )

    return openModal({ Component })
  }

  function handleSelectedAction(action: string) {
    if (action === 'Admin Detail Page') {
      const url =
        router.basePath + `${ADMIN_URL}vm/openstackserver/${server.pk}/change/`
      const target = '_blank'

      triggerLink(url, target)
    } else {
      confirmSelectedAction(action)
    }
  }

  return (
    <Select
      disabled={isLoading || disabled}
      defaultValue={''}
      onChange={(event) => handleSelectedAction(event.target.value)}
    >
      <option disabled value={''}>
        Select...
      </option>
      {actions.map((action) => (
        <option
          key={action}
          value={action}
          disabled={isOptionDisabled(server, action)}
        >
          {action}
        </option>
      ))}
    </Select>
  )
}
