import { createColumnHelper } from '@tanstack/react-table'
import { useRouter } from 'next/router'
import { useDashboardWorkloadRunsQuery } from 'sdk'
import {
  EyeIcon,
  Hint,
  Link,
  Pagination,
  queryResultToTableState,
  Table,
} from 'ui'
import {
  ACTIVE_STATES,
  LifecycleStatusIcon,
  PENDING_ACTIVE_STATES,
  PENDING_INACTIVE_STATES,
} from '../workloads/WorkloadRunListItem'

interface WorkloadRunTableProps {
  createdByUsername?: string
}

export default function WorkloadRunTable(
  props: WorkloadRunTableProps
): JSX.Element {
  const pageSize = 10
  const queryParamPrefix = 'workload_'
  const router = useRouter()

  const lifecycleStatus = [
    ...ACTIVE_STATES,
    ...PENDING_ACTIVE_STATES,
    ...PENDING_INACTIVE_STATES,
  ]
  const query = useDashboardWorkloadRunsQuery(
    {
      first: pageSize,
      offset: Number(router.query.workload_offset) || 0,
      orderBy: '-createdAt',
      createdByUsername: props.createdByUsername,
      lifecycleStatus,
    },
    {
      keepPreviousData: true,
      refetchInterval: 10 * 1000,
    }
  )
  const { data, isLoading, isSuccess } = query
  if (isLoading) {
    return <p>Loading...</p>
  }
  if (!isSuccess) {
    return <p>Loading failed, please reload to try again</p>
  }

  const workloadRuns = data.workloadRuns.edges.map((edge) => edge.node)
  const columnHelper = createColumnHelper<(typeof workloadRuns)[number]>()

  const columns = [
    columnHelper.accessor('team.name', {
      id: 'teamName',
      header: () => 'Team',
      cell: ({ row: { original: workloadRun } }) => (
        <>
          <EyeIcon />
          <Link
            href={`/teams/${workloadRun.team.slug}/workloads/${workloadRun.workloadDefinitionSlug}?status=ACTIVE`}
          >
            {workloadRun.team.name}
          </Link>
        </>
      ),
    }),
    columnHelper.accessor('namespace', {
      id: 'namespace',
      header: () => 'Namespace',
      cell: ({ row: { original: workloadRun } }) => (
        <>
          {workloadRun.namespace}
          <Hint message={workloadRun.containerRuntimeRequirements.label} />
        </>
      ),
    }),
    columnHelper.display({
      id: 'nonfilterable_Access',
      header: () => 'Access',
      cell: ({ row: { original: workloadRun } }) =>
        ACTIVE_STATES.includes(workloadRun.lifecycleStatus) ? (
          workloadRun.ingressRouteMatch ? (
            <>
              <Link
                target="_blank"
                rel="noreferrer"
                href={`https://${workloadRun.ingressRouteMatch}`}
              >
                Visit URL
              </Link>
              <Hint message={workloadRun.ingressRouteMatch} />
            </>
          ) : (
            'No ingress route'
          )
        ) : (
          <>
            <LifecycleStatusIcon
              lifecycleStatus={workloadRun.lifecycleStatus}
            />
            {workloadRun.lifecycleStatus}
          </>
        ),
    }),
  ]

  const totalCount = data.workloadRuns.totalCount || 0

  return (
    <>
      <p>
        {isLoading
          ? 'Loading instances...'
          : `${totalCount} instance${totalCount === 1 ? '' : 's'} found`}
      </p>
      <Table
        data={queryResultToTableState(query, workloadRuns)}
        columns={columns}
      />
      <Pagination
        totalCount={totalCount}
        pageSize={pageSize}
        queryParamPrefix={queryParamPrefix}
      />
    </>
  )
}
