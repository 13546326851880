import { Main, SubHeading, UserContext } from 'ui'
import ServerTable from '../../components/dashboard/ServerTable'
import WorkloadRunTable from '../../components/dashboard/WorkloadRunTable'
import styled from 'styled-components'
import { useContext } from 'react'

const ResourceTables = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
`

export default function PersonalHome(): JSX.Element {
  const { currentUser } = useContext(UserContext)
  return (
    <Main>
      <ResourceTables>
        <div>
          <SubHeading>Active Virtual Machines</SubHeading>
          <ServerTable createdByUsername={currentUser.username} />
        </div>
        <div>
          <SubHeading>Active Workloads</SubHeading>
          <WorkloadRunTable createdByUsername={currentUser.username} />
        </div>
      </ResourceTables>
    </Main>
  )
}
