import { ThemeProvider as NextThemeProvider, useTheme } from 'next-themes'
import { AppProps } from 'next/app'
import { useRouter } from 'next/router'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { FetchParamsProvider, useTeamBySlugQuery, useWhoamiQuery } from 'sdk'
import { ThemeProvider } from 'styled-components'
import {
  AppLoadingSplash,
  CloudFooter,
  DarkTheme,
  GlobalStyle,
  LightTheme,
  ModalProvider,
  TabNav,
  NavigationContextProvider,
  TopLevelGrid,
  TopNav,
} from 'ui'
import { useUrls } from 'utils'

function AppWithContext({ Component, pageProps }: AppProps) {
  const router = useRouter()
  const teamSlug =
    'teamSlug' in router.query ? String(router.query.teamSlug) : null
  const { AUTH_URL, CLOUD_URL } = useUrls()
  const whoamiQuery = useWhoamiQuery(undefined, {
    enabled: typeof window !== 'undefined',
    retry: false,
    cacheTime: Infinity,
    staleTime: Infinity,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  })

  const teamEnabled = teamSlug !== null
  const teamQuery = useTeamBySlugQuery(
    { slug: String(teamSlug) }, // Type workaround
    { enabled: teamEnabled }
  )

  if (whoamiQuery.isFetched && whoamiQuery.isError) {
    router.push(`${AUTH_URL}/?redirect=${CLOUD_URL}`)
  }

  if (whoamiQuery.isLoading || (teamEnabled && teamQuery.isLoading)) {
    return <AppLoadingSplash />
  }

  if (!whoamiQuery.isSuccess || (teamEnabled && !teamQuery.isSuccess)) {
    return <div>Loading failed, please reload to try again</div>
  }

  // Slug must match only one team if provided
  const activeTeam = teamQuery.data?.teams.edges[0]?.node

  return (
    <NavigationContextProvider
      currentUser={whoamiQuery.data}
      activeTeam={activeTeam}
    >
      <ModalProvider>
        <TopLevelGrid>
          <TopNav />
          <TabNav />
          <Component {...pageProps} />
          <CloudFooter />
          {whoamiQuery.data.whoami.isSuperuser && <ReactQueryDevtools />}
        </TopLevelGrid>
      </ModalProvider>
    </NavigationContextProvider>
  )
}

function AppWithStyles(props: AppProps) {
  const { resolvedTheme } = useTheme()
  const queryClient = new QueryClient()

  return (
    <ThemeProvider theme={resolvedTheme === 'light' ? LightTheme : DarkTheme}>
      <GlobalStyle />
      <FetchParamsProvider>
        <QueryClientProvider client={queryClient}>
          <AppWithContext {...props} />
        </QueryClientProvider>
      </FetchParamsProvider>
    </ThemeProvider>
  )
}

export default function App(props: AppProps): JSX.Element {
  return (
    <>
      <NextThemeProvider>
        <AppWithStyles {...props} />
      </NextThemeProvider>
    </>
  )
}
