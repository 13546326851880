import { createColumnHelper } from '@tanstack/react-table'
import { useRouter } from 'next/router'
import { useSearchParams } from 'next/navigation'
import { useState } from 'react'
import {
  OpenStackServerStatus,
  OpenStackServerType,
  ServersQueryVariables,
  useServersQuery,
} from 'sdk'
import {
  AddFilterButton,
  CheckCircleInactiveIcon,
  CurrentUser,
  ExclamationCircleIcon,
  Hint,
  Pagination,
  PendingActiveIcon,
  PendingInactiveIcon,
  PlayIcon,
  queryResultToTableState,
  RelativeTime,
  Search,
  SearchFilter,
  Table,
  Username,
} from 'ui'
import { useQueryParamVariables } from 'utils'
import SSHConnectionActions from '../shared/SSHConnectionActions'
import ServerActions from './ServerActions'

export function StatusIcon({
  openStackServerStatus,
}: {
  openStackServerStatus: OpenStackServerStatus
}): JSX.Element {
  switch (openStackServerStatus) {
    case OpenStackServerStatus.Active:
      return <PlayIcon />
    case OpenStackServerStatus.Error:
    case OpenStackServerStatus.InternalError:
      return <ExclamationCircleIcon />
    case OpenStackServerStatus.Deleted:
    case OpenStackServerStatus.Suspended:
    case OpenStackServerStatus.Paused:
    case OpenStackServerStatus.Shutoff:
      return <CheckCircleInactiveIcon />
    case OpenStackServerStatus.RequestDelete:
      return <PendingInactiveIcon />
    case OpenStackServerStatus.Initial:
    case OpenStackServerStatus.Build:
    case OpenStackServerStatus.RequestCreate:
    case OpenStackServerStatus.Creating:
    case OpenStackServerStatus.RequestStart:
    case OpenStackServerStatus.RequestShutoff:
    case OpenStackServerStatus.RequestSoftReboot:
    case OpenStackServerStatus.Reboot:
    case OpenStackServerStatus.RequestHardReboot:
    case OpenStackServerStatus.HardReboot:
    default:
      return <PendingActiveIcon />
  }
}

const availableFilters = [
  'name',
  'ip',
  'computeHost',
  'createdBy',
  'status',
  'teamName',
  'flavor',
  'image',
]

const ServerTableFilters: SearchFilter = {
  availableFilters,
  defaultFilters: [
    { filter: 'status', value: 'RUNNING' },
    { filter: 'status', value: 'TERMINATED' },
    { filter: 'status', value: 'FAILED' },
  ],
}

export const RUNNING_STATUSES = [
  OpenStackServerStatus.Active,
  OpenStackServerStatus.Build,
  OpenStackServerStatus.Initial,
  OpenStackServerStatus.RequestCreate,
  OpenStackServerStatus.RequestDelete,
  OpenStackServerStatus.Creating,
  OpenStackServerStatus.RequestStart,
  OpenStackServerStatus.RequestShutoff,
  OpenStackServerStatus.RequestHardReboot,
  OpenStackServerStatus.RequestSoftReboot,
  OpenStackServerStatus.Shutoff,
  OpenStackServerStatus.Reboot,
  OpenStackServerStatus.HardReboot,
  OpenStackServerStatus.Paused,
  OpenStackServerStatus.Suspended,
]

export const TERMINATED_STATUSES = [OpenStackServerStatus.Deleted]
export const FAILED_STATUSES = [
  OpenStackServerStatus.Error,
  OpenStackServerStatus.InternalError,
]

type ServerTableColumns =
  | 'name'
  | 'floatingIp'
  | 'computeHost'
  | 'createdBy'
  | 'status'
  | 'createdAt'
  | 'team'
  | 'connect'
  | 'actions'
  | 'flavor'
  | 'image'

interface ServerTableProps {
  columns: Set<ServerTableColumns>
  teamId?: string
  username?: string
  userIsActiveTeamAdmin?: boolean
  currentUser: CurrentUser
}

export default function ServerTable(props: ServerTableProps): JSX.Element {
  const pageSize = 100
  const router = useRouter()
  const searchParams = useSearchParams()

  const [variables, setVariables] = useState<ServersQueryVariables>({
    first: pageSize,
    offset: Number(router.query.offset) || 0,
    orderBy: '-createdAt',
    teamId: props.teamId,
    createdBy: props.username,
    flavor: router.query.flavor ? String(router.query.flavor) : null,
    image: router.query.image ? String(router.query.image) : null,
  })
  // Setup Variables for Query from context + Query Params

  useQueryParamVariables<
    ServersQueryVariables & { status: 'RUNNING' | 'TERMINATED' | 'FAILED' }
  >(availableFilters, variables, (variables) =>
    setVariables({
      ...variables,
      status:
        // Combine granular API states into high level states
        variables.status === 'RUNNING'
          ? RUNNING_STATUSES
          : variables.status === 'TERMINATED'
            ? TERMINATED_STATUSES
            : variables.status === 'FAILED'
              ? FAILED_STATUSES
              : searchParams.has('status')
                ? (searchParams.get('status') as OpenStackServerStatus)
                : undefined,
    })
  )

  const query = useServersQuery(variables, {
    keepPreviousData: true,
    refetchInterval: 10 * 1000,
  })
  const { data, isLoading, isSuccess } = query
  if (isLoading) {
    return <p>Loading...</p>
  }
  if (!isSuccess) {
    return <p>Loading failed, please reload to try again</p>
  }

  const servers = data.servers.edges.map(
    (edge) => edge.node as OpenStackServerType
  )

  const columnHelper = createColumnHelper<OpenStackServerType>()

  const nameColumn = columnHelper.accessor('name', {
    id: 'name',
    header: () => 'Name',
    cell: ({ row: { original: server } }) => server.name,
  })

  const flavorColumn = columnHelper.accessor('openstackFlavor', {
    id: 'openstack_flavor__name',
    header: () => 'Flavor',
    cell: ({ row: { original: server } }) => (
      <>
        {server.openstackFlavor.name}
        <AddFilterButton
          filterKey="flavor"
          filterValue={server.openstackFlavor.name}
        />
      </>
    ),
  })

  const imageColumn = columnHelper.accessor('openstackImage', {
    id: 'openstack_image__name',
    header: () => 'Image',
    cell: ({ row: { original: server } }) => (
      <>
        {server.openstackImage.name}
        <AddFilterButton
          filterKey="image"
          filterValue={server.openstackImage.name}
        />
      </>
    ),
  })

  const floatingIpColumn = columnHelper.accessor('openstackFloatingIp', {
    id: 'openstackFloatingIp',
    header: () => 'IP',
    cell: (info) => info.getValue(),
  })

  const computeHostColumn = columnHelper.accessor('computeHost', {
    id: 'computeHost',
    header: () => 'Compute Host',
    cell: (info) => {
      const host = info.getValue()
      return (
        <>
          {host}
          {host && (
            <AddFilterButton filterKey="computeHost" filterValue={host} />
          )}
        </>
      )
    },
  })

  const teamColumn = columnHelper.accessor('teamName', {
    id: 'teamName',
    header: () => 'Team',
    cell: ({ row: { original: server } }) => (
      <>
        {server.teamName}
        <AddFilterButton filterKey="teamName" filterValue={server.teamName} />
      </>
    ),
  })

  const createdByColumn = columnHelper.accessor('createdBy', {
    id: 'createdBy__username',
    header: () => 'Created By',
    cell: (info) => {
      const user = info.getValue()
      const isCurrentUser = user.username === props.currentUser.username
      return (
        <>
          <Username $isCurrentUser={isCurrentUser}>{user.username}</Username>
          <AddFilterButton filterKey="createdBy" filterValue={user.username} />
        </>
      )
    },
  })

  const statusColumn = columnHelper.accessor('status', {
    id: 'status',
    header: () => 'Status',
    cell: (info) => {
      const server = info.row.original

      return (
        <>
          <StatusIcon openStackServerStatus={server.status} />
          {server.status}
          {server.errorMessage && <Hint message={server.errorMessage} />}
          <AddFilterButton filterKey="status" filterValue={server.status} />
        </>
      )
    },
  })

  const createdAtColumn = columnHelper.accessor('createdAt', {
    id: 'createdAt',
    header: () => 'Created At',
    cell: (info) => <RelativeTime time={info.getValue()} />,
  })

  const connectColumn = columnHelper.display({
    id: 'nonfilterable_Connect',
    header: () => 'Connect',
    cell: (info) => {
      const server = info.row.original
      const host = server.openstackFloatingIp
      if (server.status === OpenStackServerStatus.Active && host) {
        return (
          <SSHConnectionActions
            user={server.openstackImage.defaultSshUser}
            host={host}
          />
        )
      }
      return null
    },
  })

  const actionsColumn = columnHelper.display({
    id: 'nonfilterable_actions',
    header: () => 'Actions',
    cell: (info) => {
      const server = info.row.original
      const id = server.id
      const nonActionableStates = [
        OpenStackServerStatus.Deleted,
        OpenStackServerStatus.InternalError,
      ]
      if (!nonActionableStates.includes(server.status) && id) {
        const isUserAuthorised =
          props.userIsActiveTeamAdmin ||
          server.createdBy.id === props.currentUser.id
        return (
          <ServerActions
            server={server}
            isLoading={isLoading}
            disabled={!isUserAuthorised}
            isSuperuser={props.currentUser.isSuperuser}
          />
        )
      }
      return null
    },
  })

  const availableColumns = {
    team: teamColumn,
    name: nameColumn,
    floatingIp: floatingIpColumn,
    computeHost: computeHostColumn,
    createdBy: createdByColumn,
    status: statusColumn,
    createdAt: createdAtColumn,
    image: imageColumn,
    flavor: flavorColumn,
    connect: connectColumn,
    actions: actionsColumn,
  }

  const columns = Array.from(props.columns).map(
    (column) => availableColumns[column]
  )

  const totalCount = data.servers.totalCount || 0

  return (
    <>
      <Search filters={ServerTableFilters} />
      <p>
        {isLoading
          ? 'Loading...'
          : `${totalCount} item${totalCount === 1 ? '' : 's'} found`}
      </p>
      <Pagination totalCount={totalCount} pageSize={pageSize} />
      <Table data={queryResultToTableState(query, servers)} columns={columns} />
      <Pagination totalCount={totalCount} pageSize={pageSize} />
    </>
  )
}
