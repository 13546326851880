import { Button, CopyIcon } from 'ui'

export default function SSHConnectionActions({
  user,
  host,
}: {
  user: string
  host: string
}): JSX.Element {
  return (
    <>
      <Button onClick={() => window.open(`ssh://${user}@${host}`, '_self')}>
        SSH
      </Button>
      <Button
        onClick={() => navigator.clipboard.writeText(`ssh ${user}@${host}`)}
      >
        <CopyIcon />
      </Button>
    </>
  )
}
